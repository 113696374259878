<template>
  <fieldset>
    <DocFieldsBaseIz
        :modelValue="data"
        :document-type-id="documentTypeId"
        :document-reg-num="documentRegNum"
        @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>
        <CheckBoxField
            :modelValue="modelValue.isChangeForProlongation"
            @update:modelValue="update('isChangeForProlongation', $event)"
        >
          <label class="label">Изменение в связи с продлением срока</label>
        </CheckBoxField>
        <TextField
            v-if="modelValue.isChangeForProlongation"
            title="Причина продления"
            :modelValue="modelValue.prolongationReason"
            @update:modelValue="update('prolongationReason', $event)"
            data-qa="reasonForRenewal"
        >
          <label class="label" for="prolongationReason"
          >Причина продления</label
          >
        </TextField>
        <TextField
            v-if="modelValue.isChangeForProlongation"
            title="Срок продления (мес)"
            :modelValue="modelValue.prolongationLimit"
            @update:modelValue="update('prolongationLimit', $event)"
            data-qa="renewalPeriod"
        >
          <label class="label" for="prolongationLimit"
          >Срок продления (мес)</label
          >
        </TextField>
        <DateField
            v-if="modelValue.isChangeForProlongation"
            :modelValue="modelValue.prolongationDate"
            @update:modelValue="
            update('prolongationDate', $event, v$.forProlongation)
          "
            :errors="v$.$error ? v$.forProlongation.$errors : []"
            data-qa="expirationDate"
        >
          <label class="label label--required">Дата действия продлена до</label>
        </DateField>
      </template>
      <template #additional-fields-bottom>
        <MultiSelectField
            :modelValue="modelValue.zu"
            @update:modelValue="updateVector($event, v$.zu)"
            :options="vectors"
            @reload="fetchWithDelay"
            :errors="v$.$error ? v$.zu.$errors : []"
            :itemValidationRule="itemValidationRule"
            keyProp="externalId"
        >
          <label class="label label--required">Земельные участки</label>
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFieldsBaseIz>
  </fieldset>
</template>

<script>
import DocIZRs from "../../../models/documents/docIZRs";
import DocFieldsBaseIz from "./DocFieldsBaseIz";
import TextField from "../../basic/form/TextField.vue";
import DateField from "../../basic/form/DateField.vue";
import MultiSelectField from "../../basic/MultiSelectField";
import {toRefs, ref} from "vue";
import CheckBoxField from "../../basic/form/CheckBoxField.vue";
import {helpers, required} from "@vuelidate/validators";
import useValidator from "./validator";
import {useVectorLandPlot} from "../../../hooks/vector";
// import GisogdDocumentsProxy from "../proxies/gisogdDocumentsProxy";

export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocIZRs(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum: {
      type: String,
      required: false
    }
  },
  // watch: {
  //   "modelValue.documentsOriginRegistration": {
  //     immediate: true,
  //     async handler(val) {
  //       if (val.length) {
  //         this.data.zu = await GisogdDocumentsProxy.GetVectorsFromDocIzRsOriginRegistration(
  //             val.map((item) => item.registrationNumber)
  //         )
  //       }
  //       return true;
  //     },
  //   },
  // },
  components: {DocFieldsBaseIz, CheckBoxField, TextField, DateField, MultiSelectField},
  setup(props, {emit}) {
    const {modelValue: data} = toRefs(props);
    const {vectors, fetchWithDelay} = useVectorLandPlot();

    const ruleForProlongation = (param) => (value) => {
      if (!data.value.isChangeForProlongation)
        return true;
      let result = helpers.req(value.prolongationDate);
      if (!result) {
        param.value = [];
        param.value.push({$message: "Заполните поле"});
      } else param.value = [];
      return result;
    };
    const errorDateProlong = ref([]);

    const validationRules = {
      forProlongation: helpers.withMessage(
          "Заполните поле",
          ruleForProlongation(errorDateProlong)
      ),
      zu: {
        required: helpers.withMessage("Заполните поле", required),
      }
    };

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    const v$ = useValidator(data, validationRules);
    v$.value.forProlongation.$errors = errorDateProlong;

    function update(fieldName, value, field) {
      const newValue = {...props.modelValue};
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    function updateVector(value, field) {
      update("zu", value, field);
      emit("update:vector", value);
    }

    // watch('data.documentsOriginRegistration', (v,a,b,c)=>{      
    //   console.log(v,a,b,c)
    // });

    return {data, update, updateVector, v$, vectors, fetchWithDelay, itemValidationRule};
  },
};
</script>